<script lang="ts">
	import doorClosingMechanism from "../assets/images/door-closing-mechanism.png?webp&h=320&imagetools";
	import doorClosingMechanismOverlay from "../assets/images/door-closing-mechanism-overlay.png?webp&h=580&imagetools";
	import liftingMechanism from "../assets/images/lifting-mechanism.png?webp&h=320&imagetools";
	import liftingMechanismOverlay from "../assets/images/lifting-mechanism-overlay.png?webp&h=580&imagetools";
	import heating from "../assets/images/heating.png?webp&h=320&imagetools";
	import heatingOverlay from "../assets/images/heating-overlay.png?webp&h=580&imagetools";
	import production from "../assets/images/production.png?webp&h=320&imagetools";
	import productionLinesOverlay from "../assets/images/production-lines.png?webp&h=580&imagetools";
	import foundry from "../assets/images/foundry.png?webp&h=320&imagetools";
	import foundryOverlay from "../assets/images/foundry-overlay.png?webp&h=580&imagetools";
	import toolRoom from "../assets/images/toolroom.png?webp&h=320&imagetools";
	import toolRoomOverlay from "../assets/images/tools.png?webp&h=580&imagetools";
	import LinkCard from "./LinkCard.svelte";
	import { getTranslate } from "../utils/getTranslate.js";
	import translations from "./AreasOfOperation.translations.json";
	import { flyInOnScroll } from "../utils/flyInOnScroll.js";
	import { getTenant } from "../contexts/tenantContextKey.js";
	import { technologyPathMap } from "../../../core/schema/paths/technologyPathMap.js";
	import { getPathInLocale } from "../../../core/schema/paths/getPathInLocale.js";

	const translate = getTranslate(translations);
	const { locale } = getTenant();

	const areasOfOperation = [
		{
			title: translate("doorClosingMechanism"),
			sectionName: translate("doorClosingMechanismSection"),
			link: { cs: "https://brano-zz.cz", en: "https://brano-zz.com" }[locale],
			image: doorClosingMechanism,
			backgroundImage: doorClosingMechanismOverlay,
		},
		{
			title: translate("liftingMechanism"),
			sectionName: translate("liftingMechanismSection"),
			link: { cs: "https://brano-zz.cz", en: "https://brano-zz.com" }[locale],
			image: liftingMechanism,
			backgroundImage: liftingMechanismOverlay,
		},
		{
			title: translate("heating"),
			sectionName: translate("heatingSection"),
			link: { cs: "https://www.moratop.cz", en: "https://www.moratop.cz/en" }[locale],
			image: heating,
			backgroundImage: heatingOverlay,
		},
		{
			title: translate("foundry"),
			sectionName: translate("foundrySection"),
			link: `/${getPathInLocale(locale, technologyPathMap)}#foundry`,
			image: foundry,
			backgroundImage: foundryOverlay,
		},
		{
			title: translate("toolRoom"),
			sectionName: translate("toolRoomSection"),
			link: `/${getPathInLocale(locale, technologyPathMap)}#toolRoom`,
			image: toolRoom,
			backgroundImage: toolRoomOverlay,
		},
		{
			title: translate("productionLines"),
			sectionName: translate("productionLinesSection"),
			link: `/${getPathInLocale(locale, technologyPathMap)}#production`,
			image: production,
			backgroundImage: productionLinesOverlay,
		},
	];
</script>

<div class="container lg:pt-[18.75rem]">
	<h2 use:flyInOnScroll class="mb-[6.25rem] text-xl font-semibold md:text-2xl lg:text-4xl">
		{translate("areaOfOperation")}
	</h2>
	<div
		class="grid grid-cols-1 place-items-center justify-center gap-5 px-5 sm:px-8 md:grid-cols-2 md:px-[4.375rem] xl:grid-cols-3"
	>
		{#each areasOfOperation as area, index}
			<LinkCard
				{index}
				title={area.title}
				sectionName={area.sectionName}
				link={area.link}
				image={area.image}
				whiteOnHover
				backgroundImage={area.backgroundImage}
				backgroundImageOverlay
			/>
		{/each}
	</div>
</div>
